<template>
    <div>
      <Footer></Footer>
        <div class="content">
          
          <span class="title">
            “大唐杯”全国大学生新一代信息通信技术大赛章程
          </span>
  
          <div class="imgbox">
            <!-- <img  style="width:100%;height: 100%;" src="./Framework_img/img_A.jpg" alt="">  -->
          </div>
  
          <div class="contbox">
            <p class="text"> 一、大赛理念与宗旨</p>
            <p class="text_t">为深入学习贯彻落实党的二十大精神，加快建设国家战略人才力量，努力培养造就更多大师、战略科学家、一流科技领军人才和创新团队、青年科技人才、卓越工程师、大国工匠、高技能人才。根据《基础学科人才培养规划（2021—2035年）》和《国家职业教育改革实施方案》有关文件精神，推动信息通信技术产业的发展，服务信息通信产业发展大局，深化产教融合、校企合作，创新高校人才培养机制，为产业数字化发展提供丰富的人才储备，培养具有创新能力和实践能力的高端人才，提升高校毕业生的就业竞争力，特组织开展“大唐杯”全国大学生新一代信息通信技术大赛（原“大唐杯”全国大学生移动通信5G技术大赛）（以下简称“大赛”）。</p>
            <p class="text_t">大赛以推广信息通信领域前沿技术、协同高校学科建设、推动学生创新创业为目的，旨在激发新时代信息技术人才发展新动能，提升学生5G技术理论与工程实践创新能力，匹配工科学生毕业要求、现场工程师岗位能力要求及卓越工程师培养要求，推进高校“双一流”及“双高”建设，促进电子信息类相关专业教学内容和教学方法的改革创新，推动5G+垂直产业应用创新，助力电子信息领域学生未来创业，促进高校信息通信技术的研究和成果转化，助力电子信息类专业高质量发展。</p>
  

            
            <p class="text_title"> 二、组织构架</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">（一）组织机构及职责</p>
            <p class="text_t">主办单位：工业和信息化部人才交流中心、中国通信企业协会</p>
            <p class="text_t">承办单位：中信科移动通信技术股份有限公司、大唐移动通信设备有限公司、北京市教委北京高校电子信息类专业群</p>
            <p class="text_t">协办单位：北方工业大学、北京邮电大学、武汉职业技术学院</p>
            <p class="text_t">竞赛承办方与协办方职责</p>
            <p class="text_t">（1）负责拟订竞赛场地的建设方案。</p>
            <p class="text_t">（2）负责竞赛场地建设的指导与监督。</p>
            <p class="text_t">（3）负责竞赛场地的使用与维护。</p>
            <p class="text_t">（4）负责赛区整体活动的组织、管理、协调工作。</p>
            <p class="text_t">（5）负责具体工作机构的建立及各组工作职责的指定。</p>
            <p class="text_t">（6）负责审定各执行组的预案、预算。</p>
            <p class="text_t">（7）负责制定应急管理办法，处理和协调竞赛期间突发事件，确保赛区竞赛活动的顺利进行。</p>
            <p class="text_t">（8）负责起草、设计大赛的各类图片、音像视频等宣传稿件、资料等。</p>
            <p class="text_t">（9）负责比赛现场、开闭幕式的主持、宣传和布置工作。</p>
            <p class="text_t">（10）负责大赛各种证件的设计、制作、管理和发放。</p>
            <p class="text_t">（11）负责赛区日常事务的组织、协调、记录。</p>
            <p class="text_t">（12）负责大赛有关文件的整理并归档，交于组委会秘书处备份。</p>
            <p class="text_t">（13）完成大赛组委会秘书处交办的其他工作。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">（二）大赛组委会及职责</p>
            <p class="text_t">“大唐杯”全国大学生新一代信息通信技术大赛（原“大唐杯”全国大学生移动通信5G技术大赛）组委会设在工业和信息化部人才交流中心，负责全国范围内的大赛组织工作。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">组委会职责</p>
            <p class="text_t">（1）负责全国范围内的大赛活动统筹组织工作，并对专家委员会和秘书处提交的问题进行协商和裁决。</p>
            <p class="text_t">（2）审议、修改、通过竞赛章程。</p>
            <p class="text_t">（3）协调主办方筹集竞赛组织、评审、奖励所需的经费。</p>
            <p class="text_t">（4）议决其他应由组委会决议的事项。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">大赛组委会下设秘书处，由主办方、承办方共同组成，负责大赛的具体组织工作。</p>
            <p class="text_t">（1）依照组委会的工作意图，及时传达组委会的工作要求。</p>
            <p class="text_t">（2）负责与大赛各相关单位的沟通与协调。</p>
            <p class="text_t">（3）负责大赛各个工作组之间的联络协调，做好服务工作。</p>
            <p class="text_t">（4）负责起草拟订组委会的各种文件。</p>
            <p class="text_t">（5）负责大赛相关例会、专题会等会议安排，整理会议纪要，并对会议议定的事项进行催办落实和信息反馈。</p>
            <p class="text_t">（6）负责制定、执行大赛各类项目预算。</p>
            <p class="text_t">（7）负责提供竞赛平台、及其后续定制开发、运营和维护。</p>
            <p class="text_t">（8）负责拟定全部竞赛环节的设计、并落地实施。</p>
            <p class="text_t">（9）负责保持与组委会专家、大赛评委的有效沟通。</p>
            <p class="text_t">（10）根据竞赛需要及时提出调整建议。</p>
            <p class="text_t">（11）负责制定大赛宣传方案、完成具体的会务组织与实施。</p>
            <p class="text_t">（12）负责起草、撰写竞赛相关的宣传文字与新闻稿件。</p>
            <p class="text_t">（13）负责大赛日常活动网络信息的发布、更新。</p>
            <p class="text_t">（14）完成组委会交办的其他竞赛相关事务。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">（三）大赛专家委员会及职责</p>
            <p class="text_t">大赛设立全国专家委员会，由主办单位统一聘请的相关学科具有高级职称的专家组成。专家委员会经组委会批准成立，负责竞赛命题与方向、拟定竞赛章程与大纲、独立开展竞赛成绩验收评审等工作。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">大赛专家委员会职责</p>
            <p class="text_t">（1）负责拟定竞赛大纲，制定竞赛题目形式与详细题目，保证题目的合理性与公平性。</p>
            <p class="text_t">（2）协助秘书处完成竞赛章程与评审规则，并制定评审实施细则。</p>
            <p class="text_t">（3）负责竞赛成绩验收与评审。</p>
            <p class="text_t">（4）授权全国组委会秘书处在成绩公示期结束前，接受参赛学校和学生、评委、教师对竞赛成绩的质疑与投诉，并对投诉者的姓名、单位予以保密。</p>
            <p class="text_t">（5）成绩公示期结束前，如出现被质疑投诉成绩或题目，专家组应召开会议，依据投诉者需提供相关证据或明确的线索，复核所涉及成绩与题目，进行复核公示或通知，并对复核结果负责。如投诉者仍然对成绩或比赛结果有异议，则由大赛专家委员会提交至大赛仲裁委员会进行仲裁。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">（四）赛事合作咨询接洽处</p>
            <p class="text_t">赛事合作咨询接洽处由大赛组委会组织，并经过大赛组委会资格审核，接洽处处长由1人组成，设接洽员１名。赛事合作咨询接洽处在大赛组委会领导下开展工作，并对大赛组委会负责。</p>
            <!-- <p class="text_t" style="font-weight: 600;margin: .2rem auto;">大赛仲裁委员会职责</p>
            <p class="text_t">（1）熟悉本次大赛相关赛项的竞赛规程和规则。</p>
            <p class="text_t">（2）掌握本赛项的竞赛进展情况。</p>
            <p class="text_t">（3）受理各参赛选手提交的书面申诉与专家组提交的书面申诉。</p>
            <p class="text_t">（4）对受理的申诉进行深入调查与复核，做出客观、公正的集体仲裁。</p> -->
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">（五）大赛仲裁委员会及职责</p>
            <p class="text_t" >大赛仲裁委员会由大赛组委会组织，并经过大赛组委会资格审核，裁仲裁组由3人组成，设组长１名。大赛仲裁委员会在大赛组委会领导下开展工作，并对大赛组委会负责。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">大赛仲裁委员会职责</p>
            <p class="text_t">（1）熟悉本次大赛相关赛项的竞赛规程和规则。</p>
            <p class="text_t">（2）掌握本赛项的竞赛进展情况。</p>
            <p class="text_t">（3）受理各参赛选手提交的书面申诉与专家组提交的书面申诉。</p>
            <p class="text_t">（4）对受理的申诉进行深入调查与复核，做出客观、公正的集体仲裁。</p>
           
           
           
           
            <p class="text_title"> 三、大赛申诉与仲裁程序</p>
            <p class="text_t">（1）各参赛队对不符合大赛和赛项规程规定的仪器设备、材料、物件、竞赛使用工具、用品，赛场管理、竞赛成绩，以及工作人员的不规范行为等，可向赛项仲裁委员会提出书面申诉；</p>
            <p class="text_t">（2）申诉主体为省赛与国赛参赛队伍指导教师；</p>
            <p class="text_t">（3）申诉启动时，参赛队以该队指导教师签字同意的书面报告的形式递交赛项仲裁委员会，报告应对申诉事件的现象、发生时间、涉及人员、申诉依据等进行充分、实事求是的叙述。非书面申诉不予受理。</p>
            <p class="text_t">（4）申诉应在对应赛项比赛结束后24小时内提出，超过申诉时效不予受理。</p>
            <p class="text_t">（5）仲裁委员会在收到书面申诉报告后的24小时内组织复议，并及时将复议结果以书面形式告知申诉方，申诉方对复议结果仍有异议，可由学校教师代表向大赛委员会提出申诉。大赛委员会审核后的仲裁结果为最终结果。</p>
            <p class="text_t">为保证大赛的公平、公正，对全国赛和各赛区省赛的初步评审结果执行监督反馈制度。投诉反馈期自公布评审初步结果之日起，为期3天，过期不再受理。</p>
            <p class="text_t">投诉反馈期间，各赛区大赛组委会和全国大赛组委会将受理有关违反大赛比赛章程、规则和纪律的行为等。投诉和异议须以书面形式提出。由个人提出的异议，须注本人的真实姓名、工作单位、通信地址，并附有本人亲笔签名；由单位提出的异议，须注明单位指定联系人的姓名、通信地址、电话，并加盖单位公章。否则组委会将不受理。各赛区大赛组委会和全国大赛组委会须对提出异议的个人或单位严格保密。</p>
            <p class="text_t">“大唐杯”全国大学生新一代信息通信技术大赛（“大唐杯”全国大学生移动通信5G技术大赛）组委会保留对本章程的最终解释权。</p>




            <p class="text_title"> 四、赛道与赛制</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">赛道一：信息通信工程实践赛</p>
            <p class="text_t">该赛道以5G技术为核心，融合信息通信系统认知、5G网络关键技术、通信网络工程实践案例、人工智能、智能网联汽车、智慧电网等垂直产业特色应用案例。本赛道按参赛阶段分为省赛阶段和全国总决赛阶段。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">赛道二：5G+创新应用仿真设计赛</p>
            <p class="text_t">该赛道以体现5G技术的应用价值为核心，以5G技术赋能垂直产业应用为导向，鼓励跨专业组队，采用指定的虚拟仿真开发环境和自选硬件平台作为载体，由竞赛团队自主命题，最终输出项目设计及开发成果，该赛道按参赛阶段分为省赛阶段和全国总决赛阶段。</p>
            
            
            
            
            <p class="text_title"> 五、评选办法及奖项设置</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">（一）省赛评选办法及奖项设置</p>
            <p class="text_t" style="font-weight: 600;">1．信息通信工程实践赛奖项</p>
            <p class="text_t">按赛区组别分别设置一等奖、二等奖、三等奖，获奖比例为各组别实际参赛组数的10%、20%、30%。</p>
            <p class="text_t" style="font-weight: 600;">2．5G+创新应用仿真设计赛</p>
            <p class="text_t">按竞赛小组省赛成绩和项目里程碑达成质量综合评估设置一等奖、二等奖、三等奖，其中一等奖晋级全国总决赛。</p>
            <p class="text_t" style="font-weight: 600;">3．优秀组织协同奖</p>
            <p class="text_t">经大赛组委会审批，成功申报省赛赛点的单位或参赛组织工作成绩突出的单位，获“‘大唐杯’全国大学生新一代信息通信技术大赛优秀组织协同单位”称号。</p>
            <p class="text_t" style="font-weight: 600;margin: .2rem auto;">（二）全国赛评选办法及奖项设置</p>
            <p class="text_t" style="font-weight: 600;">1．信息通信工程实践赛奖项</p>
            <p class="text_t">全国总决赛按组别分别设置一、二、三等奖，获奖比例为实际参赛组数的10%、20%、30%。获得优秀奖的选手名单不录入全国普通高校大学生竞赛分析数据库。</p>
            <p class="text_t" style="font-weight: 600;">2．5G+创新应用仿真设计赛</p>
            <p class="text_t">全国总决赛设置一、二、三等奖，获奖比例为实际竞赛小组数量的5%、10%、20%。获得优秀奖的选手名单不录入全国普通高校大学生竞赛分析数据库。</p>

            <p class="text_t" style="font-weight: 600;">3．指导教师奖</p>
            <p class="text_t">获奖竞赛小组指导教师可获得“‘大唐杯’全国大学生新一代信息通信技术大赛优秀指导教师”称号。</p>

            <p class="text_t" style="font-weight: 600;">4．优秀组织协同奖 </p>
            <p class="text_t">参赛组织工作成绩突出、经大赛组委会审批符合相关条件的单位，获“‘大唐杯’全国大学生新一代信息通信技术大赛优秀组织协同单位”称号。</p>

            <p class="text_t" style="font-weight: 600;">5．其他奖</p>
            <p class="text_t">综合考虑大赛举办的影响力及质量要求，特殊情况下由大赛组委会发起设置其他奖项。</p>
            <p class="text_t" style="margin-left: 5rem; margin-top: .6rem;width: 100%;">“大唐杯”全国大学生新一代信息通信技术大赛组委会</p>

            <div class="img_box">
            </div>
          </div>
        </div>
  
    <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
    export default {
      name: 'Home',
      components: {
          Footer,
          Bottom
      },
      data(){
        return {
  
        }
      },
      methods:{
      },
      mounted(){
  
      },
      created(){
  
      },
    }
    </script>
    
    <style scoped lang="scss">
    @import './Regulations.scss';
    </style>
    